window.onload = function () {
  const button = document.getElementById('scrollTopButton');
  const footer = document.querySelector('footer');

  function isFooterVisible() {
    const rect = footer.getBoundingClientRect();
    return rect.top >= 0 && rect.bottom <= window.innerHeight;
    // return rect.top <= window.innerHeight && rect.bottom >= 0;
  }

  window.onscroll = function () {
    if (isFooterVisible()) {
      button.style.display = 'block';
    } else {
      button.style.display = 'none';
    }
  };
};
